import { useContext, useState, useRef, useEffect } from 'react';
import { getIn } from 'formik';
import {
  FormGroup,
  Label,
  Input as NativeInput,
  FormFeedback,
  FormText,
  Nav,
  NavItem,
  NavLink,
  Row,
  Button,
} from 'reactstrap';
import classnames from 'classnames';
import PI from 'react-phone-input-2';
import { useTranslation } from 'react-i18next';
import EmojiPicker from 'emoji-picker-react';

import AsyncNativeSelect from '../AsyncSelect';
import NativeSelect from 'components/Select';
import FileField from 'components/FileField';
import { ParlorContext } from 'src/context.jsx';
import { RichEditorExample } from './RichTextEditor';
import { findCountryCode } from 'helpers/find-country-code';
import { useToggle } from 'hooks/gql.hooks';
import './style.css';
import { getCountryCode } from 'helpers/getCountryCode';
import { SlateEditor } from './SlateEditor';

const PhoneInput = PI.default ? PI.default : PI;

export const languages = [
  { value: 'en', label: 'EN' },
  { value: 'pl', label: 'PL' },
  { value: 'de', label: 'DE' },
  { value: 'uk', label: 'UA' },
  { value: 'ru', label: 'RU' },
];

export const Input = ({
  field: { ...fields },
  form: { touched, errors, ...rest },
  helpText,
  inline,
  onChange,
  ...props
}) => (
  <FormGroup>
    {!inline ? (
      <dl className='row'>
        {props.label && (
          <dt className='col-md-3'>
            <Label for={props.name}>{props.label}</Label>
          </dt>
        )}
        <dd className={props.label ? 'col-md-9' : 'col-md-12'}>
          <NativeInput
            onWheel={(e) => e.target.blur()}
            id={props.name}
            {...fields}
            onChange={(e) => {
              const value = e.target.value;
              rest.setFieldValue(fields.name, value);
              if (onChange) onChange(value);
            }}
            invalid={Boolean(getIn(touched, fields.name) && getIn(errors, fields.name))}
            {...props}
          />
          {getIn(touched, fields.name) && getIn(errors, fields.name) && (
            <FormFeedback>{getIn(errors, fields.name)}</FormFeedback>
          )}
          {helpText && <FormText>{helpText}</FormText>}
        </dd>
      </dl>
    ) : (
      <>
        <Label for={props.name}>{props.label}</Label>
        <NativeInput
          onWheel={(e) => e.target.blur()}
          id={props.name}
          {...props}
          {...fields}
          onChange={(e) => {
            const value = e.target.value;
            rest.setFieldValue(fields.name, value);
            if (props.onChange) props.onChange(value);
          }}
          invalid={Boolean(getIn(touched, fields.name) && getIn(errors, fields.name))}
        />
        {getIn(touched, fields.name) && getIn(errors, fields.name) && (
          <FormFeedback>{getIn(errors, fields.name)}</FormFeedback>
        )}
        {helpText && <FormText>{helpText}</FormText>}
      </>
    )}
  </FormGroup>
);

export const getTopLevelDomain = () => {
  const languageChange = { uk: 'en', com: 'uk', ee: 'et', cz: 'cs', ca: 'en' };
  const hostname = window.location.hostname;
  // const hostname = 'cr.vean-tattoo.pl';
  const parts = hostname.split('.');
  let domain = parts[parts.length - 1];
  if (languageChange[domain]) {
    domain = languageChange[domain];
  }
  return domain;
};

export const getLanguages = () => {
  const languages = [
    { value: 'uk', label: 'UA' },
    { value: 'ru', label: 'RU' },
  ];
  const domain = getTopLevelDomain();
  if (!languages.find((f) => f.value === domain)) {
    languages.push({ value: domain, label: domain.toUpperCase() });
  }
  return languages;
};

export const InputMultiLang = ({
  field: { ...fields },
  form: { touched, errors, ...rest },
  helpText,
  onChange,
  languge = false,
  ...props
}) => {
  const allLanguage = [
    { label: 'RU', value: 'ru' },
    { label: 'EN', value: 'en' },
    { label: 'UA', value: 'uk' },
    { label: 'DE', value: 'de' },
    { label: 'PL', value: 'pl' },
  ];
  const languages = languge ? allLanguage : getLanguages();

  const [lang, setLang] = useState('ru');
  const extractParts = (str) => {
    const parts = str.split('_');
    const lang = parts.pop();
    const field = parts.join('_');
    return { field, lang };
  };

  const langError = Object.keys(errors).reduce((acc, key) => {
    const { field, lang } = extractParts(key);
    if (!acc[field]) {
      acc[field] = { langs: [] };
    }
    acc[field].langs.push(lang);
    return acc;
  }, {});

  return (
    <FormGroup>
      <>
        <Nav className='mb-3' tabs>
          {languages.map((el) => {
            return (
              <NavItem key={el.value}>
                <NavLink
                  className={classnames({ active: lang === el.value })}
                  onClick={() => {
                    setLang(el.value);
                    rest.setFieldValue(
                      [`${fields.name}_${lang}`],
                      props.values[`${fields.name}_${lang}`],
                    );
                  }}
                >
                  <span
                    style={{
                      color:
                        Object.keys(langError).includes(fields.name) &&
                        langError[fields.name].langs.length > 0 &&
                        langError[fields.name].langs.includes(el.value)
                          ? 'red'
                          : 'white',
                    }}
                  >
                    {el.label}
                  </span>
                </NavLink>
              </NavItem>
            );
          })}
        </Nav>
        <dl className='row'>
          {props.label && (
            <dt className='col-md-3'>
              <Label for={props.name}>{props.label}</Label>
            </dt>
          )}
          <dd className={props.label ? 'col-md-9' : 'col-md-12'}>
            <NativeInput
              onWheel={(e) => e.target.blur()}
              id={props.name}
              {...fields}
              onChange={(e) => {
                const value = e.target.value;
                console.log(value, 'value');
                rest.setFieldValue([`${fields.name}_${lang}`], value);
                if (onChange) onChange(value);
              }}
              invalid={Boolean(
                getIn(touched, `${fields.name}_${lang}`) && getIn(errors, `${fields.name}_${lang}`),
              )}
              {...props}
              value={props.values[`${fields.name}_${lang}`] ?? ''}
            />
            {getIn(touched, `${fields.name}_${lang}`) &&
              getIn(errors, `${fields.name}_${lang}`) && (
                <FormFeedback>{getIn(errors, `${fields.name}_${lang}`)}</FormFeedback>
              )}
            {helpText && <FormText>{helpText}</FormText>}
          </dd>
        </dl>
      </>
    </FormGroup>
  );
};

export const InputEmoji = ({
  field: { ...fields },
  form: { touched, errors, ...rest },
  helpText,
  inline,
  onChange,
  ...props
}) => {
  const inputRef = useRef();

  const [isEmojiOpen, setIsEmojiOpen] = useToggle(false);
  return (
    <FormGroup>
      {!inline ? (
        <dl className='row'>
          {props.label && (
            <dt className='col-md-3'>
              <Label for={props.name}>{props.label}</Label>
            </dt>
          )}
          <dd className={props.label ? 'col-md-9 picker-container' : 'col-md-12 picker-container'}>
            <NativeInput
              onWheel={(e) => e.target.blur()}
              id={props.name}
              {...fields}
              onChange={(e) => {
                const value = e.target.value;
                rest.setFieldValue(fields.name, value);
                if (onChange) onChange(value);
              }}
              invalid={Boolean(getIn(touched, fields.name) && getIn(errors, fields.name))}
              {...props}
              ref={inputRef}
            />
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              class='bi bi-emoji-smile-fill emoji-icon'
              viewBox='0 0 16 16'
              onClick={() => setIsEmojiOpen()}
            >
              <path d='M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5M4.285 9.567a.5.5 0 0 1 .683.183A3.5 3.5 0 0 0 8 11.5a3.5 3.5 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683M10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8' />
            </svg>
            {isEmojiOpen && (
              <EmojiPicker
                style={{ width: '100%' }}
                theme='dark'
                onEmojiClick={(el) => {
                  rest.setFieldValue(fields.name, inputRef.current.props.value + el.emoji);
                }}
              />
            )}
            {getIn(touched, fields.name) && getIn(errors, fields.name) && (
              <FormFeedback>{getIn(errors, fields.name)}</FormFeedback>
            )}
            {helpText && <FormText>{helpText}</FormText>}
          </dd>
        </dl>
      ) : (
        <>
          <Label for={props.name}>{props.label}</Label>
          <NativeInput
            onWheel={(e) => e.target.blur()}
            id={props.name}
            {...props}
            {...fields}
            onChange={(e) => {
              const value = e.target.value;
              rest.setFieldValue(fields.name, value);
              if (props.onChange) props.onChange(value);
            }}
            invalid={Boolean(getIn(touched, fields.name) && getIn(errors, fields.name))}
          />
          {getIn(touched, fields.name) && getIn(errors, fields.name) && (
            <FormFeedback>{getIn(errors, fields.name)}</FormFeedback>
          )}
          {helpText && <FormText>{helpText}</FormText>}
        </>
      )}
    </FormGroup>
  );
};

export const DraftText = ({
  field,
  // eslint-disable-next-line no-unused-vars
  form: { ...rest },
  ...props
}) => (
  <FormGroup>
    {props.label && <Label for={field.name}>{props.label}</Label>}
    <RichEditorExample
      id={field.name}
      editorState={field.value}
      onChange={(value) => rest.setFieldValue(field.name, value)}
      {...props}
    />
  </FormGroup>
);

export const SlateText = ({
  field,
  // eslint-disable-next-line no-unused-vars
  form: { ...rest },
  ...props
}) => (
  <FormGroup>
    {props.label && <Label for={field.name}>{props.label}</Label>}
    <SlateEditor getHtml={(value) => editValue(value)} initialValueHtml={data?.title} />
    <SlateEditor
      id={field.name}
      editorState={field.value}
      onChange={(value) => rest.setFieldValue(field.name, value)}
      {...props}
    />
  </FormGroup>
);

export const Select = ({
  field,
  form: { touched, errors, ...rest },
  onChange,
  value,
  isJSX,
  ...props
}) => (
  <NativeSelect
    {...props}
    error={getIn(errors, field.name)}
    touched={touched}
    disabled={props.disabled}
    name={field.name}
    value={value || field.value}
    onChange={(o) => {
      rest.setFieldValue(field.name, o);
      if (onChange) onChange(o);
    }}
    filterOption={
      isJSX
        ? (option, searchText) => {
            if (option.label.props?.children[1].toLowerCase().includes(searchText.toLowerCase())) {
              return true;
            } else {
              return false;
            }
          }
        : undefined
    }
    handleBlur={() => rest.setFieldTouched(true)}
  />
);

export const AsyncSelect = ({ field, form: { touched, ...rest }, onChange, value, ...props }) => (
  <AsyncNativeSelect
    {...props}
    name={field.name}
    value={value || field.value}
    onChange={(o) => {
      rest.setFieldValue(field.name, o);
      if (onChange) onChange(o);
    }}
    handleBlur={() => rest.setFieldTouched(true)}
    touched={getIn(touched, field.name)}
  />
);

export const Checkbox = ({
  field: { ...fields },
  // eslint-disable-next-line no-unused-vars
  form: { touched, errors, ...rest },
  helpText,
  onChange,
  disabled = false,
  ...props
}) => (
  <FormGroup check>
    <Label check>
      <NativeInput
        type='checkbox'
        checked={fields.value}
        name={fields.name}
        onChange={(e) => {
          rest.setFieldValue(fields.name, e.target.checked);
          if (onChange) onChange(e);
        }}
        invalid={Boolean(getIn(touched, fields.name) && getIn(errors, fields.name))}
        disabled={disabled}
      />
      <span className='form-check-sign'>
        <span className='check' /> {props.label}
      </span>
      {getIn(touched, fields.name) && getIn(errors, fields.name) && (
        <FormFeedback>{getIn(errors, fields.name)}</FormFeedback>
      )}
    </Label>
    {helpText && <FormText>{helpText}</FormText>}
  </FormGroup>
);

export const File = ({ field, form: { touched, errors, setFieldValue }, ...props }) => (
  <FileField
    name={field.name}
    label={props.label}
    setFieldValue={setFieldValue}
    value={field.value}
    touched={touched}
    error={getIn(errors, field.name)}
    accept={props.accept}
    {...props}
  />
);

export const Phone = ({
  field,
  form: { touched, errors, ...rest },
  helpText,
  inline,
  onChange,
  ...props
}) => {
  const { parlorData } = useContext(ParlorContext);
  return (
    <FormGroup>
      {!inline ? (
        <dl className='row'>
          {props.label && (
            <dt className='col-md-3'>
              <Label for={field.name}>{props.label}</Label>
            </dt>
          )}
          <dd className={props.label ? 'col-md-9' : 'col-md-12'}>
            <PhoneInput
              inputExtraProps={{ id: field.name }}
              country={getCountryCode() === 'com' ? 'ua' : getCountryCode()}
              value={field.value}
              inputStyle={{
                backgroundColor: '#3c9883',
                width: 'inherit',
                paddingLeft: '50px',
              }}
              buttonClass='buttonPhone'
              dropdownClass='dropdownPhone'
              onChange={(value) => {
                const phone = value.replace(/[^0-9]+/g, '');
                rest.setFieldValue(field.name, `+${phone}`);
                if (onChange) onChange(phone);
              }}
              onBlur={() => rest.setFieldTouched(field.name, true)}
            />
            {getIn(touched, field.name) && getIn(errors, field.name) && (
              <FormFeedback className='d-block'>{getIn(errors, field.name)}</FormFeedback>
            )}
            {helpText && <FormText>{helpText}</FormText>}
          </dd>
        </dl>
      ) : (
        <>
          <Label for={field.name}>{props.label}</Label>
          <PhoneInput
            inputExtraProps={{ id: field.name }}
            defaultCountry={findCountryCode(parlorData?.country_code)}
            value={field.value}
            inputStyle={{ backgroundColor: '#3c9883', width: 'inherit' }}
            buttonClass='buttonPhone'
            dropdownClass='dropdownPhone'
            onChange={(value) => {
              const phone = value.replace(/[^0-9]+/g, '');
              rest.setFieldValue(field.name, `+${phone}`);
              if (onChange) onChange(phone);
            }}
            onBlur={() => rest.setFieldTouched(field.name, true)}
          />
          {getIn(touched, field.name) && getIn(errors, field.name) && (
            <FormFeedback className='d-block'>{getIn(errors, field.name)}</FormFeedback>
          )}
          {helpText && <FormText>{helpText}</FormText>}
        </>
      )}
    </FormGroup>
  );
};

export const InputField = ({
  field: { ...fields },
  form: { touched, errors, ...rest },
  helpText,
  inline,
  ...props
}) => (
  <FormGroup>
    {!inline ? (
      <dl className='row'>
        {props.label && (
          <dt className='col-md-3'>
            <Label for={props.name}>{props.label}</Label>
          </dt>
        )}
        <dd className={props.label ? 'col-md-9' : 'col-md-12'}>
          <NativeInput
            onWheel={(e) => e.target.blur()}
            id={props.name}
            {...fields}
            onChange={(e) => {
              const value = e.target.value;
              rest.setFieldValue(fields.name, value);
              if (props.handleChange) props.handleChange(value);
            }}
            invalid={Boolean(getIn(touched, fields.name) && getIn(errors, fields.name))}
            {...props}
          />
          {getIn(touched, fields.name) && getIn(errors, fields.name) && (
            <FormFeedback>{getIn(errors, fields.name)}</FormFeedback>
          )}
          {helpText && <FormText>{helpText}</FormText>}
        </dd>
      </dl>
    ) : (
      <>
        <Label for={props.name}>{props.label}</Label>
        <NativeInput
          onWheel={(e) => e.target.blur()}
          id={props.name}
          {...props}
          {...fields}
          onChange={(e) => {
            const value = e.target.value;
            rest.setFieldValue(fields.name, value);
            if (props.handleChange) props.handleChange(value);
          }}
          invalid={Boolean(getIn(touched, fields.name) && getIn(errors, fields.name))}
        />
        {getIn(touched, fields.name) && getIn(errors, fields.name) && (
          <FormFeedback>{getIn(errors, fields.name)}</FormFeedback>
        )}
        {helpText && <FormText>{helpText}</FormText>}
      </>
    )}
  </FormGroup>
);

export const MultiLangInputFormik = ({ content, setContent, name }) => {
  const { t } = useTranslation();
  const [lang, setLang] = useState('en');

  return (
    <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
      <Nav tabs>
        {languages.map((el) => {
          return (
            <NavItem key={el.value}>
              <NavLink
                className={classnames({ active: lang === el.value })}
                onClick={() => {
                  setLang(el.value);
                  setContent([`${name}_${lang}`], content[`${name}_${lang}`]);
                }}
              >
                {el.label}
              </NavLink>
            </NavItem>
          );
        })}
      </Nav>
      <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
        <NativeInput
          onChange={(value) => setContent([`${name}_${lang}`], value.target.value)}
          value={content[`${name}_${lang}`]}
        />
      </Row>
    </Row>
  );
};

export const MultiLangInput = ({ content, getValues, name }) => {
  const { t } = useTranslation();
  const [lang, setLang] = useState('en');
  const [value, setValue] = useState(content);

  useEffect(() => {
    // Initialize value based on content and available languages
    const initialValues = {};
    languages.forEach((el) => {
      initialValues[`${name}_${el.value}`] = content[`${name}_${el.value}`] || '';
    });
    setValue(initialValues);
  }, [content, name]);

  const updateValues = () => {
    getValues(value);
  };
  console.log(value, lang, name, 'value');
  return (
    <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
      <Nav tabs>
        {languages.map((el) => (
          <NavItem key={el.value}>
            <NavLink
              className={classnames({ active: lang === el.value })}
              onClick={() => {
                setLang(el.value);
              }}
            >
              {el.label}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
        <NativeInput
          onChange={(e) =>
            setValue((prevValue) => ({
              ...prevValue,
              [`${name}_${lang}`]: e.target.value,
            }))
          }
          value={value[`${name}_${lang}`] ?? ''}
        />

        <Button onClick={updateValues}>Обновить</Button>
      </Row>
    </Row>
  );
};

export const DraftTextMultiLang = ({
  field: { ...fields },
  form: { touched, errors, ...rest },
  languge = false,
  helpText,
  onChange,
  ...props
}) => {
  const allLanguage = [
    { label: 'RU', value: 'ru' },
    { label: 'EN', value: 'en' },
    { label: 'UA', value: 'uk' },
    { label: 'DE', value: 'de' },
    { label: 'PL', value: 'pl' },
  ];
  const languages = languge ? allLanguage : getLanguages();

  const [lang, setLang] = useState('ru');
  const extractParts = (str) => {
    const parts = str.split('_');
    const lang = parts.pop();
    const field = parts.join('_');
    return { field, lang };
  };

  const langError = Object.keys(errors).reduce((acc, key) => {
    const { field, lang } = extractParts(key);
    if (!acc[field]) {
      acc[field] = { langs: [] };
    }
    acc[field].langs.push(lang);
    return acc;
  }, {});

  return (
    <FormGroup>
      <>
        <Nav className='mb-3' tabs>
          {languages.map((el) => {
            return (
              <NavItem key={el.value}>
                <NavLink
                  className={classnames({ active: lang === el.value })}
                  onClick={() => {
                    setLang(el.value);
                    rest.setFieldValue(
                      [`${fields.name}_${lang}`],
                      props.values[`${fields.name}_${lang}`],
                    );
                  }}
                >
                  <span
                    style={{
                      color:
                        Object.keys(langError).includes(fields.name) &&
                        langError[fields.name].langs.length > 0 &&
                        langError[fields.name].langs.includes(el.value)
                          ? 'red'
                          : 'white',
                    }}
                  >
                    {el.label}
                  </span>
                </NavLink>
              </NavItem>
            );
          })}
        </Nav>
        {props.label && <Label for={fields.name}>{props.label}</Label>}
        <RichEditorExample
          id={fields.name}
          editorState={props.values[`${fields.name}_${lang}`] ?? fields.value}
          onChange={(value) => {
            rest.setFieldValue(`${fields.name}_${lang}`, value);
            if (onChange) onChange(value);
          }}
          {...props}
        />
        {getIn(touched, `${fields.name}_${lang}`) && getIn(errors, `${fields.name}_${lang}`) && (
          <FormFeedback>{getIn(errors, `${fields.name}_${lang}`)}</FormFeedback>
        )}
        {helpText && <FormText>{helpText}</FormText>}
      </>
    </FormGroup>
  );
};
