import { useContext, cloneElement } from 'react';
import { UserContext } from 'src/context.jsx';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form } from 'reactstrap';
import { useToggle } from 'hooks/gql.hooks';
import { handleAxiosErrorSwal, Toast } from 'helpers';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { EditorState, Modifier } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import {
  Input as FormikInput,
  Select as FormikSelect,
  File as FormikFile,
  DraftText,
} from 'components/Forms/FormikComponents';
import { useTranslation } from 'react-i18next';
import { categoryTicketOptions, isBlockFunctional } from '../service';

const FormTicket = ({ triggerComponent }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useToggle();
  const { api_v2 } = useContext(UserContext);

  const formValidator = Yup.object().shape({
    description: Yup.object().required('Обязательное поле'),
  });

  const handleSubmit = (values) => {
    const url = isBlockFunctional ? '/ticket-project/support/' : '/ticket-project/';
    let contentState = values.description.getCurrentContent();
    const selectionState = values.description.getSelection();

    contentState = Modifier.insertText(contentState, selectionState, '\n Контакт для связи: ');

    const newSelection = contentState.getSelectionAfter();
    contentState = Modifier.insertText(contentState, newSelection, values.link);

    contentState = Modifier.applyInlineStyle(
      contentState,
      contentState.getSelectionAfter(),
      'BOLD',
    );

    const newEditorState = EditorState.push(values.description, contentState, 'insert-characters');

    const data = {
      ...values,
      category: values.category.value,
      files_data: values?.files_data?.map((el) => el.base64),
      description: stateToHTML(newEditorState.getCurrentContent()),
    };

    return api_v2
      .post(url, data)
      .then(() => {
        setIsOpen();
        Toast.fire({
          icon: 'success',
        });
      })
      .catch(handleAxiosErrorSwal);
  };

  return (
    <>
      {cloneElement(triggerComponent, { onClick: setIsOpen })}

      <Modal size='lg' isOpen={isOpen}>
        <Formik
          initialValues={{
            type: 'support',
            category: null,
            link: '',
            files_data: [],
            description: EditorState.createEmpty(),
          }}
          validationSchema={formValidator}
          onSubmit={handleSubmit}
        >
          {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
            <Form onSubmit={handleSubmit} autoComplete='off' id='yourFormId'>
              <ModalHeader toggle={() => setIsOpen()} tag='h4'>
                Поддержка
              </ModalHeader>

              <ModalBody>
                <Field
                  name='category'
                  label={'Категория'}
                  component={FormikSelect}
                  options={categoryTicketOptions}
                  inline={true}
                />
                <Field name='description' label={'Описание'} component={DraftText} />
                <Field
                  name='files_data'
                  label={'Файлы'}
                  type='file'
                  component={FormikFile}
                  inline={true}
                />
                <Field
                  name='link'
                  label={'Контакт для связи'}
                  component={FormikInput}
                  inline={true}
                />
              </ModalBody>
              <ModalFooter>
                <Button color='primary' type='submit' disabled={isSubmitting}>
                  Отправить
                </Button>
                <Button color='danger' onClick={() => setIsOpen()}>
                  Закрыть
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default FormTicket;
