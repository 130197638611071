import { gql } from '@apollo/client';

export const fetchOrdersFromParlorQuery = (sorted, selectParlor, dateFilterType) => gql`
query fetchParlorOrders($store: [Int!], $from_parlors: [Int], $parlor: Int, $unofficial_order: Boolean, $status: [smallint!], $offset: Int!, $from: timestamptz, $to: timestamptz) {
  orders_count:  store_order_aggregate(
    where: {
    core_parlor: {
      is_active: {_eq: true}
    }
    store_orderfromparlors: {core_parlor: {id: {_in: $from_parlors}}}
    ${dateFilterType === 'sending_date' ? 'sending_date: {_gte: $from, _lte: $to}' : ''}
    ${dateFilterType === 'created_at' ? 'created_at: {_gte: $from, _lte: $to}' : ''}
    ${
      dateFilterType === 'payed_at'
        ? 'expenses_incomingpayment:{ payed_at: {_gte: $from, _lte: $to}}'
        : ''
    }
    unofficial_order: {_eq: $unofficial_order }, 
    status: {_in: $status}}) {
    aggregate {
      count
    }
  },
  total_orders_id:  store_order(
    where: {
    core_parlor: {
      is_active: {_eq: true}
    }
    ${dateFilterType === 'sending_date' ? 'sending_date: {_gte: $from, _lte: $to}' : ''}
    ${dateFilterType === 'created_at' ? 'created_at: {_gte: $from, _lte: $to}' : ''}
    ${
      dateFilterType === 'payed_at'
        ? 'expenses_incomingpayment:{ payed_at: {_gte: $from, _lte: $to}}'
        : ''
    }
    store_orderfromparlors: {core_parlor: {id: {_in: $from_parlors}}}
    unofficial_order: {_eq: $unofficial_order },
    status: {_in: $status}}
    ) {
    id
  },
  orders: store_order(
    limit: 10,
    offset: $offset,
    where: {
      core_parlor: {
        is_active: {_eq: true}
      }
      ${dateFilterType === 'sending_date' ? 'sending_date: {_gte: $from, _lte: $to}' : ''}
      ${dateFilterType === 'created_at' ? 'created_at: {_gte: $from, _lte: $to}' : ''}
    ${
      dateFilterType === 'payed_at'
        ? 'expenses_incomingpayment:{ payed_at: {_gte: $from, _lte: $to}}'
        : ''
    }
    store_orderfromparlors: {core_parlor: {id: {_in: $from_parlors}}}
      unofficial_order: {_eq: $unofficial_order }, 
      status: {_in: $status}},
    ${
      sorted
        ? `order_by: {${sorted[0]?.id}: ${sorted[0]?.desc ? 'desc' : 'asc'}}`
        : 'order_by: { created_at: desc }'
    })
    
  {
    id
    unofficial_order
    created_at
    sending_date
    status
    note
    trade_code
    payment_id
    receiver_id
    sender_id
    nds
    sender_parlor: store_orderfromparlors {
      parlor: core_parlor {
        id
        name
      }
    }
    source: store_ordersource {
      fine_delivery
    }
    not_turned: store_orderitems_aggregate(where: {turned: {_is_null: true}}) {
      aggregate {
        count
      }
    }
    parlor: core_parlor {
      id
      name
    }
    payment: expenses_incomingpayment {
      id
      payed_at
      store_storecard {
        label: credit_card
        value: id
      }
      value
    }
    positive: store_orderadditionaloperations_aggregate(where: {type: {_eq: "positive"}, is_active: {_eq: true}}) {
      aggregate {
        sum {
          value
        }
      }
    }
    negative: store_orderadditionaloperations_aggregate(where: {type: {_eq: "negative"}, is_active: {_eq: true}}) {
      aggregate {
        sum {
          value
        }
      }
    }
  }
}
`;

export const fetchTotalOrdersParlorToParlor = (selectParlor, dateFilterType) => gql`
  query fetchParlorOrdersTotal($store: [Int!], $parlor: Int, $unofficial_order: Boolean, $status: [smallint!], $from: timestamptz, $to: timestamptz){
    total_price: store_order(
      where: {
        core_parlor: {
          is_active: {
            _eq: true
          }
        }
        ${dateFilterType === 'sending_date' ? 'sending_date: {_gte: $from, _lte: $to}' : ''}
        ${dateFilterType === 'created_at' ? 'created_at: {_gte: $from, _lte: $to}' : ''}
        ${
          dateFilterType === 'payed_at'
            ? 'expenses_incomingpayment:{ payed_at: {_gte: $from, _lte: $to}}'
            : ''
        }
        unofficial_order: {_eq: $unofficial_order}
        store_id: {_in: $store}, 
        store_orderfromparlors: {core_parlor: {id: {_eq: $parlor}}}
        status: {_in: $status}},
        
    ) {
      price
      unofficial_order
      order_items: store_orderitems(where: {is_active: {_eq: true}}) {
        provided_price
        received
        value
        turned
        purchase_price
        purchase_delivery_price
        store_orderitemattributes {
          store_attribute {
            id
            name
            value
          }
        }
      }
      positive: store_orderadditionaloperations_aggregate(where: {type: {_eq: "positive"}, is_active: {_eq:true}}) {
        aggregate {
          sum {
            value
          }
        }
      }
      negative: store_orderadditionaloperations_aggregate(where: {type: {_eq: "negative"}, is_active: {_eq:true}}) {
        aggregate {
          sum {
            value
          }
        }
      }
    },
  }
`;

export const onlineShops = gql`
  query onlineshops($store: Int!) {
    onlineshops: store_onlineshop(where: { store_id: { _eq: $store } }) {
      access_token
      app_id
      app_secret_key
      client_id
      client_secret
      domain_online_shop
      domain_shop
      instance_id
      id
      store_id
      online_shop
    }
  }
`;

export const fetchTotalOrdersParlor = (selectParlor, dateFilterType, cardFilter) => gql`
  query fetchParlorOrdersTotal($store: [Int!], $parlor: Int, $unofficial_order: Boolean, $status: [smallint!], $from: timestamptz, $to: timestamptz, ${
    cardFilter ? `$card: Int,` : ``
  }){
    total_price: store_order(
      where: {
        core_parlor: {
          is_active: {
            _eq: true
          }
        }
        ${cardFilter ? `store_storecard: {id : {_eq: $card}}` : ``}
        ${dateFilterType === 'sending_date' ? 'sending_date: {_gte: $from, _lte: $to}' : ''}
        ${dateFilterType === 'created_at' ? 'created_at: {_gte: $from, _lte: $to}' : ''}
        ${
          dateFilterType === 'payed_at'
            ? 'expenses_incomingpayment:{ payed_at: {_gte: $from, _lte: $to}}'
            : ''
        }
        unofficial_order: {_eq: $unofficial_order}
        store_id: {_in: $store}, 
        parlor_id: ${selectParlor ? '{_eq: $parlor}' : '{_is_null: false}'}, 
        status: {_in: $status}},
        
    ) {
      price
      unofficial_order
      order_items: store_orderitems(where: {is_active: {_eq: true}}) {
        provided_price
        received
        value
        turned
        purchase_price
        purchase_delivery_price
        store_orderitemattributes {
          store_attribute {
            id
            name
            value
          }
        }
      }
      positive: store_orderadditionaloperations_aggregate(where: {type: {_eq: "positive"}, is_active: {_eq:true}}) {
        aggregate {
          sum {
            value
          }
        }
      }
      negative: store_orderadditionaloperations_aggregate(where: {type: {_eq: "negative"}, is_active: {_eq:true}}) {
        aggregate {
          sum {
            value
          }
        }
      }
    },
  }
`;

const fetchOrdersParlor = (queryName, sorted, selectParlor, dateFilterType, cardFilter) => gql`
${queryName} fetchParlorOrders($store: [Int!], $parlor: Int, $unofficial_order: Boolean, $status: [smallint!], $offset: Int!, $from: timestamptz, $to: timestamptz, ${
  cardFilter ? `$card: Int,` : ``
}) {
  orders_count:  store_order_aggregate(
    where: {
    core_parlor: {
      is_active: {_eq: true}
    }
    ${cardFilter ? `store_storecard: {id : {_eq: $card}}` : ``}
    ${dateFilterType === 'sending_date' ? 'sending_date: {_gte: $from, _lte: $to}' : ''}
    ${dateFilterType === 'created_at' ? 'created_at: {_gte: $from, _lte: $to}' : ''}
    ${
      dateFilterType === 'payed_at'
        ? 'expenses_incomingpayment:{ payed_at: {_gte: $from, _lte: $to}}'
        : ''
    }
    unofficial_order: {_eq: $unofficial_order }
    store_id: {_in: $store}, parlor_id: ${
      selectParlor ? '{_eq: $parlor}' : '{_is_null: false}'
    }, status: {_in: $status}}) {
    aggregate {
      count
    }
  },
  total_orders_id:  store_order(
    where: {
    core_parlor: {
      is_active: {_eq: true}
    }
    ${cardFilter ? `store_storecard: {id : {_eq: $card}}` : ``}
    ${dateFilterType === 'sending_date' ? 'sending_date: {_gte: $from, _lte: $to}' : ''}
    ${dateFilterType === 'created_at' ? 'created_at: {_gte: $from, _lte: $to}' : ''}
    ${
      dateFilterType === 'payed_at'
        ? 'expenses_incomingpayment:{ payed_at: {_gte: $from, _lte: $to}}'
        : ''
    }
    unofficial_order: {_eq: $unofficial_order }
    store_id: {_in: $store}, parlor_id: ${
      selectParlor ? '{_eq: $parlor}' : '{_is_null: false}'
    }, status: {_in: $status}}
    ) {
    id
  },
  orders: store_order(
    limit: 10,
    offset: $offset,
    where: {
      core_parlor: {
        is_active: {_eq: true}
      }
      ${cardFilter ? `store_storecard: {id : {_eq: $card}}` : ``}
      ${dateFilterType === 'sending_date' ? 'sending_date: {_gte: $from, _lte: $to}' : ''}
      ${dateFilterType === 'created_at' ? 'created_at: {_gte: $from, _lte: $to}' : ''}
    ${
      dateFilterType === 'payed_at'
        ? 'expenses_incomingpayment:{ payed_at: {_gte: $from, _lte: $to}}'
        : ''
    }
      unofficial_order: {_eq: $unofficial_order }
      store_id: {_in: $store}, 
      parlor_id: ${selectParlor ? '{_eq: $parlor}' : '{_is_null: false}'}, 
      status: {_in: $status}},
    ${
      sorted
        ? `order_by: {${sorted[0]?.id}: ${sorted[0]?.desc ? 'desc' : 'asc'}}`
        : 'order_by: { created_at: desc }'
    })
    
  {
    id
    unofficial_order
    created_at
    sending_date
    status
    note
    trade_code
    payment_id
    receiver_id
    sender_id
    nds
    from_parlor: store_orderfromparlors {
      parlor: core_parlor {
        id
        name
      }
    }
    source: store_ordersource {
      fine_delivery
    }
    store: store_store {
      id
      name
      store_store_owners {
        accounts_user {
          id
          first_name
          middle_name
          last_name
        }
      }
    }
    not_turned: store_orderitems_aggregate(where: {turned: {_is_null: true}}) {
      aggregate {
        count
      }
    }
    parlor: core_parlor {
      id
      name
    }
    payment: expenses_incomingpayment {
      id
      payed_at
      store_storecard {
        label: credit_card
        value: id
      }
      value
    }
    positive: store_orderadditionaloperations_aggregate(where: {type: {_eq: "positive"}, is_active: {_eq: true}}) {
      aggregate {
        sum {
          value
        }
      }
    }
    negative: store_orderadditionaloperations_aggregate(where: {type: {_eq: "negative"}, is_active: {_eq: true}}) {
      aggregate {
        sum {
          value
        }
      }
    }
    order_item: store_orderitems(where: {is_active: {_eq: true}}) {
      item: store_item {
        provided_price
        nds
      }
      provided_price
      turned
      received
      store_unit {
        id
        name
        value
        cost
      }
      value
      store_orderitemattributes {
        store_attribute {
          id
          name
          value
        }
        store_collectionattribute {
          name
          id
        }
      }
    }
  }
}
`;

export const fetchTotalOrders = (
  cardFilter,
  senderFilter,
  receiverFilter,
  sorted,
  customer,
  dateFilterType,
) => gql`
query fetchOrdersTotal($stores: [Int!], $parlor: Boolean, $unofficial_order: Boolean, $status: [smallint!],
  ${cardFilter ? `$card: Int,` : ``}
  ${receiverFilter ? `$receiver: Int,` : `$receiver: Boolean`}
  ${customer ? `$customer: Int` : `$customer: Boolean`}
  $from: timestamptz, $to: timestamptz
  ){
  total_price:  store_order(where: { 
    ${dateFilterType === 'sending_date' ? 'sending_date: {_gte: $from, _lte: $to}' : ''}
    ${dateFilterType === 'created_at' ? 'created_at: {_gte: $from, _lte: $to}' : ''}
    ${
      dateFilterType === 'payed_at'
        ? 'expenses_incomingpayment: {payed_at: {_gte: $from, _lte: $to}}'
        : ''
    }
    unofficial_order: {_eq: $unofficial_order}
    parlor_id: {_is_null: true},
    store_id: {_in: $stores}
    status: {_in: $status},
    ${cardFilter ? `store_storecard: {id : {_eq: $card}}` : ``}
    ${receiverFilter ? `receiver_id:  {_eq: $receiver}` : `receiver_id: {_is_null: $receiver }`}
    ${customer ? `customer_id: {_eq: $customer}` : `customer_id: {_is_null: $customer}`}
    price_type: {_neq: wholesale_price}
    }) {
      price
      unofficial_order
      price_type
      receiver: customers_receiver {
          is_vat
      }
      order_items: store_orderitems(where: {is_active: {_eq: true}}) {
        provided_price
        received
        value
        turned
        nds
        purchase_price
        purchase_delivery_price
        item: store_item {
          nds
        }
        store_orderitemattributes {
          store_attribute {
            id
            name
            value
          }
        }
      }
      positive: store_orderadditionaloperations_aggregate(where: {type: {_eq: "positive"}, is_active: {_eq:true}}) {
        aggregate {
          sum {
            value
          }
        }
      }
      negative: store_orderadditionaloperations_aggregate(where: {type: {_eq: "negative"}, is_active: {_eq:true}}) {
        aggregate {
          sum {
            value
          }
        }
      }
    }
}
`;

export const fetchCustomerOrders = (cardFilter, senderFilter, sorted, dateFilterType) => gql`
query fetchOrders($stores: [Int!], $unofficial_order: Boolean, $status: [smallint!], $offset: Int!,
   ${cardFilter ? `$card: Int,` : ``}
   $from: timestamptz, $to: timestamptz
   ) {
  orders_count:  store_order_aggregate(where: { 
    ${dateFilterType === 'sending_date' ? 'sending_date: {_gte: $from, _lte: $to}' : ''}
    ${dateFilterType === 'created_at' ? 'created_at: {_gte: $from, _lte: $to}' : ''}
    ${
      dateFilterType === 'payed_at'
        ? 'expenses_incomingpayment: {payed_at: {_gte: $from, _lte: $to}}'
        : ''
    }
    unofficial_order: {_eq: $unofficial_order}
    store_id: {_in: $stores}
    status: {_in: $status},
    ${cardFilter ? `store_storecard: {id : {_eq: $card}}` : ``}
    customer_id: {_is_null: false}
    } ) {
    aggregate {
      count
    }
  },
  total_orders_id:  store_order(where: { 
    ${dateFilterType === 'sending_date' ? 'sending_date: {_gte: $from, _lte: $to}' : ''}
    ${dateFilterType === 'created_at' ? 'created_at: {_gte: $from, _lte: $to}' : ''}
    ${
      dateFilterType === 'payed_at'
        ? 'expenses_incomingpayment: {payed_at: {_gte: $from, _lte: $to}}'
        : ''
    }
    unofficial_order: {_eq: $unofficial_order}
    store_id: {_in: $stores}
    status: {_in: $status},
    ${cardFilter ? `store_storecard: {id : {_eq: $card}}` : ``}
    customer_id: {_is_null: false}
    } ) {
    id
  },
  orders: store_order(limit: 10, offset: $offset, where: {
    ${dateFilterType === 'sending_date' ? 'sending_date: {_gte: $from, _lte: $to}' : ''}
    ${dateFilterType === 'created_at' ? 'created_at: {_gte: $from, _lte: $to}' : ''}
    ${
      dateFilterType === 'payed_at'
        ? 'expenses_incomingpayment: {payed_at: {_gte: $from, _lte: $to}}'
        : ''
    }
    unofficial_order: {_eq: $unofficial_order}
    store_id: {_in: $stores}
    status: {_in: $status},
    ${cardFilter ? `store_storecard: {id : {_eq: $card}}` : ``}
    customer_id: {_is_null: false}
  }, ${
    sorted
      ? `order_by: {${sorted[0]?.id}: ${sorted[0]?.desc ? 'desc' : 'asc'}}`
      : 'order_by: { created_at: desc }'
  } ) {
    id
    unofficial_order
    created_at
    sending_date
    status
    note
    trade_code
    payment_id
    receiver_id
    sender_id
    nds
    customer_id
    price
    positive: store_orderadditionaloperations_aggregate(where: {type: {_eq: "positive"}, is_active: {_eq:true}}) {
      aggregate {
        sum {
          value
        }
      }
    }
    negative: store_orderadditionaloperations_aggregate(where: {type: {_eq: "negative"}, is_active: {_eq:true}}) {
      aggregate {
        sum {
          value
        }
      }
    }
    customer: customers_customer {
      id
      full_name
    }
    store: store_store {
      id
      name
      store_store_owners {
        accounts_user {
          id
          first_name
          middle_name
          last_name
        }
      }
    }
    not_turned: store_orderitems_aggregate(where: {turned: {_is_null: true}, is_active: {_eq: true}}) {
      aggregate {
        count
      }
    }
    parlor: core_parlor {
      id
      name
    }
    payment: expenses_incomingpayment {
      id
      payed_at
      store_storecard {
        label: credit_card
        value: id
      }
      value
    }
    order_item: store_orderitems(where: {is_active: {_eq: true}}) {
      item: store_item {
        provided_price
        nds
        sell_other_countries_price
        provided_price
      }
      provided_price
      shop_price
      sell_other_countries_price
      turned
      received
      store_unit {
        id
        name
        value
        cost
      }
      value
      store_orderitemattributes {
        store_attribute {
          id
          name
          value
        }
        store_collectionattribute {
          name
          id
        }
      }
    }
  }
}
`;

export const fetchTotalOrdersCustomer = (cardFilter, sorted, dateFilterType) => gql`
query fetchCustomerOrdersTotal($stores: [Int!],  $unofficial_order: Boolean, $status: [smallint!],
  ${cardFilter ? `$card: Int,` : ``}
  $customer: Boolean,
  $from: timestamptz, $to: timestamptz
  ){
  total_price:  store_order(where: { 
    ${dateFilterType === 'sending_date' ? 'sending_date: {_gte: $from, _lte: $to}' : ''}
    ${dateFilterType === 'created_at' ? 'created_at: {_gte: $from, _lte: $to}' : ''}
    ${
      dateFilterType === 'payed_at'
        ? 'expenses_incomingpayment: {payed_at: {_gte: $from, _lte: $to}}'
        : ''
    }
    unofficial_order: {_eq: $unofficial_order}
    store_id: {_in: $stores}
    status: {_in: $status},
    ${cardFilter ? `store_storecard: {id : {_eq: $card}}` : ``}
    customer_id: {_is_null: false}
    }) {
      price
      unofficial_order
      price_type
      order_items: store_orderitems(where: {is_active: {_eq: true}}) {
        provided_price
        received
        value
        turned
        nds
        purchase_price
        purchase_delivery_price
        item: store_item {
          nds
        }
        store_orderitemattributes {
          store_attribute {
            id
            name
            value
          }
        }
      }
      positive: store_orderadditionaloperations_aggregate(where: {type: {_eq: "positive"}, is_active: {_eq:true}}) {
        aggregate {
          sum {
            value
          }
        }
      }
      negative: store_orderadditionaloperations_aggregate(where: {type: {_eq: "negative"}, is_active: {_eq:true}}) {
        aggregate {
          sum {
            value
          }
        }
      }
    }
}
`;

const fetchOrders = (
  queryName,
  cardFilter,
  senderFilter,
  receiverFilter,
  sorted,
  customer,
  dateFilterType,
) => gql`
${queryName} fetchOrders($stores: [Int!], $parlor: Boolean, $unofficial_order: Boolean, $status: [smallint!], $offset: Int!,
   ${cardFilter ? `$card: Int,` : ``}
   ${receiverFilter ? `$receiver: Int,` : `$receiver: Boolean`}
   ${customer ? `$customer: Int` : `$customer: Boolean`}
   $from: timestamptz, $to: timestamptz
   ) {
  orders_count:  store_order_aggregate(where: { 
    ${dateFilterType === 'sending_date' ? 'sending_date: {_gte: $from, _lte: $to}' : ''}
    ${dateFilterType === 'created_at' ? 'created_at: {_gte: $from, _lte: $to}' : ''}
    ${
      dateFilterType === 'payed_at'
        ? 'expenses_incomingpayment: {payed_at: {_gte: $from, _lte: $to}}'
        : ''
    }
    unofficial_order: {_eq: $unofficial_order}
    store_id: {_in: $stores}
    parlor_id: {_is_null: true},
    status: {_in: $status},
    price_type: {_neq: wholesale_price}
    ${cardFilter ? `store_storecard: {id : {_eq: $card}}` : ``}
    ${receiverFilter ? `receiver_id:  {_eq: $receiver}` : `receiver_id: {_is_null: $receiver }`}
    ${customer ? `customer_id: {_eq: $customer}` : `customer_id: {_is_null: $customer}`}
    } ) {
    aggregate {
      count
    }
  },
  total_orders_id:  store_order(where: { 
    ${dateFilterType === 'sending_date' ? 'sending_date: {_gte: $from, _lte: $to}' : ''}
    ${dateFilterType === 'created_at' ? 'created_at: {_gte: $from, _lte: $to}' : ''}
    ${
      dateFilterType === 'payed_at'
        ? 'expenses_incomingpayment: {payed_at: {_gte: $from, _lte: $to}}'
        : ''
    }
    unofficial_order: {_eq: $unofficial_order}
    store_id: {_in: $stores}
    parlor_id: {_is_null: true},
    status: {_in: $status},
    price_type: {_neq: wholesale_price}
    ${cardFilter ? `store_storecard: {id : {_eq: $card}}` : ``}
    ${receiverFilter ? `receiver_id:  {_eq: $receiver}` : `receiver_id: {_is_null: $receiver }`}
    ${customer ? `customer_id: {_eq: $customer}` : `customer_id: {_is_null: $customer}`}
    } ) {
    id
  },
  orders: store_order(limit: 10, offset: $offset, where: {
    ${dateFilterType === 'sending_date' ? 'sending_date: {_gte: $from, _lte: $to}' : ''}
    ${dateFilterType === 'created_at' ? 'created_at: {_gte: $from, _lte: $to}' : ''}
    ${
      dateFilterType === 'payed_at'
        ? 'expenses_incomingpayment: {payed_at: {_gte: $from, _lte: $to}}'
        : ''
    }
    unofficial_order: {_eq: $unofficial_order}
    store_id: {_in: $stores}
    parlor_id: {_is_null: true},
    status: {_in: $status},
    price_type: {_neq: wholesale_price}
    ${cardFilter ? `store_storecard: {id : {_eq: $card}}` : ``}
    ${receiverFilter ? `receiver_id:  {_eq: $receiver}` : `receiver_id: {_is_null: $receiver }`}
    ${customer ? `customer_id: {_eq: $customer}` : `customer_id: {_is_null: $customer}`}
  }, ${
    sorted
      ? `order_by: {${sorted[0]?.id}: ${sorted[0]?.desc ? 'desc' : 'asc'}}`
      : 'order_by: { created_at: desc }'
  } ) {
    id
    unofficial_order
    created_at
    sending_date
    status
    note
    trade_code
    payment_id
    receiver_id
    sender_id
    nds
    customer_id
    price
    positive: store_orderadditionaloperations_aggregate(where: {type: {_eq: "positive"}, is_active: {_eq:true}}) {
      aggregate {
        sum {
          value
        }
      }
    }
    negative: store_orderadditionaloperations_aggregate(where: {type: {_eq: "negative"}, is_active: {_eq:true}}) {
      aggregate {
        sum {
          value
        }
      }
    }
    customer: customers_customer {
      id
      full_name
    }
    receiver: customers_receiver {
      name
      id
    }
    store: store_store {
      id
      name
      store_store_owners {
        accounts_user {
          id
          first_name
          middle_name
          last_name
        }
      }
    }
    not_turned: store_orderitems_aggregate(where: {turned: {_is_null: true}, is_active: {_eq: true}}) {
      aggregate {
        count
      }
    }
    parlor: core_parlor {
      id
      name
    }
    payment: expenses_incomingpayment {
      id
      payed_at
      store_storecard {
        label: credit_card
        value: id
      }
      value
    }
    order_item: store_orderitems(where: {is_active: {_eq: true}}) {
      item: store_item {
        provided_price
        nds
        sell_other_countries_price
        provided_price
      }
      provided_price
      shop_price
      sell_other_countries_price
      turned
      received
      store_unit {
        id
        name
        value
        cost
      }
      value
      store_orderitemattributes {
        store_attribute {
          id
          name
          value
        }
        store_collectionattribute {
          name
          id
        }
      }
    }
  }
}
`;

const fetchSingleOrder = (queryName) => gql`
${queryName} fetchOrder($orderId: Int!) {
  order: store_order_by_pk(id: $orderId) {
    id
    status
    status_history
    note
    price
    updated_at
    sending_date
    price_type
    customer_id
    created_by_id
    unofficial_order
    from_parlor: store_orderfromparlors {
      parlor: core_parlor {
        id
        name
      }
      parlor_wallet_id
      wallet: transactions_parlorwallet {
        id
        name
      }
    }
    shoper: store_ordershopers {
      delivery: shipping_price
    }
    customer: customers_customer {
      full_name
      phone_number
    }
    customers_receiver {
      price
      is_vat
      type
      crm_domain: core_countryconfiguration {
        value: id
        label: country
        api
        currency_id
      }
    }
    files: store_orderfiles {
      file
      id
      order_id
    }
    store_storecard {
      credit_card
      id
    }
    parlor: core_parlor {
      id
      name
    }
    payment: expenses_incomingpayment {
      id
      value
      payed
      image
      payed_at
      payed_by: accountsUserByPayedById {
        id
        first_name
        middle_name
        last_name
        role
      }
    }
    trade_code
    store: store_store {
      id
      name
      store_store_owners {
        accounts_user {
          id
          first_name
          middle_name
          last_name
        }
      }
    }
    additional_operations: store_orderadditionaloperations(where: {is_active: {_eq: true}}) {
      type
      value
      id
      additional_operation: store_additionaloperation {
        name
      }
    }
    items: store_orderitems(where: {is_active: {_eq: true}}, order_by: {id: asc}) {
      id
      purchase_price
      purchase_delivery_price
      shop_price
      provided_price
      sell_price
      nds
      sell_other_countries_price
      wholesale_price
      orderitemexpirationdates: store_orderitemexpirationdates(where: {is_active: {_eq: true}}) {
        id
        expirationdate: store_itemexpirationdate {
          end_date
          id
        }
      }
      item: store_item {
        id
        sell_other_countries_price
        wholesale_price
        shop_price
        sell_price
        image
        parent: parent_id
        item_code
        is_collection
        item_collections: store_collectionitems {
          id
          amount
          is_positive_item

          item: storeItemByItemId {
            name
            attribute: store_attribute {
              name
            }
            store_amount
            sent: store_orderitems_aggregate(where: {is_active: {_eq: true}, store_order: {status: {_in: ["500", "1500"]}, payment_id: {_is_null: true}}}) {
              aggregate {
                sum {
                  value
                }
              }
            }
          }
        }
        category: store_category {
          id
          name
          parent_id
        }
        name
        units
        store_amount
        nds
        is_expiration_date
        preorders: store_orderitems_aggregate(where: {store_order: {status: {_in: ["100", "150"]}}}) {
          aggregate {
            sum {
              value
            }
          }
        }
        sent: store_orderitems_aggregate(where: {is_active: {_eq: true}, store_order: {status: {_in: ["500", "1500"]}, payment_id: {_is_null: true}}}) {
          aggregate {
            sum {
              value
            }
          }
        }
        expected: store_warehouseitems_aggregate(where: {store_warehouselog: {status: {_eq: "100"}, is_active: {_eq: true}}}) {
          aggregate {
            sum {
              value
            }
          }
        }
        store_attribute {
          id
          name
        }
        item_units: store_item_units_news {
          unit: store_unit {
            id
            name
            value
            cost
            title
          }
        }
        store_item_collection_attributes {
          store_collectionattribute {
            store_collectionattributes_attributes {
              store_attribute {
                name
                id
                value
              }
            }
            name
            id
          }
        }
      }
      value
      received
      turned
      store_orderitemattributes {
        store_attribute {
          id
          name
          value
        }
        store_collectionattribute {
          name
          id
        }
      }
      store_unit {
        id
        name
        value
        cost
      }
    }
    sender: customers_sender {
      id
    }
    receiver: customers_receiver {
      id
      prepayment
      name
      type
    }
  }
}`;
export const fetchOrdersParlorQuery = (sorted, selectParlor, dateFilterType, cardFilter) =>
  fetchOrdersParlor('query', sorted, selectParlor, dateFilterType, cardFilter);

export const fetchOrdersQuery = (
  cardFilter,
  senderFilter,
  receiverFilter,
  sorted,
  customer,
  dateFilterType,
) =>
  fetchOrders('query', cardFilter, senderFilter, receiverFilter, sorted, customer, dateFilterType);

export const fetchSingleOrderQuery = fetchSingleOrder('query');

const SHOP_WIDGETS = (queryName) => gql`
${queryName} fetchShop($store: Int!, $from: timestamptz!, $to: timestamptz!) {
  store: store_store_by_pk(id: $store) {
    id
    name
    cash
    orders: store_orders(where: {expenses_incomingpayment: {payed: {_eq: true}, payed_at: {_gte: $from, _lte: $to}}}) {
      id
      payment: expenses_incomingpayment {
        id
        value
      }
    }
    incoming_payments: store_orders(where: {expenses_incomingpayment: {payed: {_eq: false}, canceled: {_eq: false}}}) {
      id
      payment: expenses_incomingpayment {
        id
        value
      }
    }
    expenses: store_warehouseexpenses_aggregate(where: {created_at: {_gte: $from, _lte: $to}}) {
      aggregate {
        sum {
          amount
        }
      }
    }
  }
}`;

export const fetchShopWidgetsQuery = SHOP_WIDGETS('query');

export const WAREHOUSE = (
  queryName,
  purchasePriceFilter,
  providedPriceFilter,
  sellPriceFilter,
  wholesalePriceFilter,
  shopPriceFilter,
  sellOtherCountriesPriceFilter,
  storeAmountFilter,
  ndsFilter,
  isModerator,
) => gql`
${queryName} fetchStoreItems(
      $store: Int!,
  ) {
  items: store_item(where:
    {
      store_category: {
        store_id: {_eq: $store}}
        ${isModerator ? '' : ', available: {_eq: true}'}

      ${
        purchasePriceFilter ||
        providedPriceFilter ||
        sellPriceFilter ||
        wholesalePriceFilter ||
        shopPriceFilter ||
        sellOtherCountriesPriceFilter ||
        storeAmountFilter ||
        ndsFilter
          ? `, store_itemstorepropositions: {
        status: {_eq: "new"}
        ${purchasePriceFilter ? ', purchase_price: {_is_null: false}' : ''}
        ${providedPriceFilter ? ', provided_price: {_is_null: false}' : ''}
        ${sellPriceFilter ? ', sell_price: {_is_null: false}' : ''}
        ${wholesalePriceFilter ? ', wholesale_price: {_is_null: false}' : ''}
        ${shopPriceFilter ? ', shop_price: {_is_null: false}' : ''}
        ${sellOtherCountriesPriceFilter ? ', sell_other_countries_price: {_is_null: false}' : ''}
        ${storeAmountFilter ? ', store_amount: {_is_null: false}' : ''}
        ${ndsFilter ? ', nds: {_is_null: false}' : ''}
        }`
          : ''
      }
    }) {
    id
    parent_id
    item_code
    provided_price
    purchase_price
    sell_other_countries_price
    wholesale_price
    sell_price
    store_amount
    shop_price
    available
    description
    image
    nds
    pay_full_points
    pay_half_points
    sell_other_countries_price_hide
    sell_price_hide
    shop_price_hide
    purchase_price_hide
    provided_price_hide
    wholesale_price_hide
    comment_of_moderator
    comment_of_owner
    is_collection
    can_be_produced
    store_componentitems: storeComponentitemsByProducedItemId {
      id
      amountInItem: amount
      item_id
    }
    store_collectionitems {
      id
      amountInCollection: amount
      item: storeItemByItemId {
        id
      }
    }
    store_item_collection_attributes {
      id
      collectionattributes_id
    }
    attribute: store_attribute {
      name
      id
    }
    item_units: store_item_units_news {
      unit: store_unit {
        id
        name
        value
        cost
      }
    }
    name
    units
    category: store_category {
      id
      name
      parent_id
    }
    store_orderitems_aggregate(where: {store_order: {trade_code: {_is_null: false}, expenses_incomingpayment: {payed: {_eq: false}}}}) {
      aggregate {
        sum {
          value
        }
      }
    }
    preorders: store_orderitems_aggregate(where: {is_active: {_eq: true}, store_order: {status: {_in: ["100", "150"]}}}) {
      aggregate {
        sum {
          value
        }
      }
    }
    marketable: store_orderitems(limit: 10, where: {is_active: {_eq: true}}) {
      id
      details: store_order {
        created_at
      }
    }
    store_orderitems(where: {is_active: {_eq: true}, store_order: {trade_code: {_is_null: false}, expenses_incomingpayment: {payed: {_eq: false}}}}) {
      id
      item_id
      purchase_price
      value
      received
      details: store_order {
        created_at
        status
      }
    }
    store_warehouseitems_aggregate(where: {store_warehouselog: {status: {_eq: "100"}, store_id: {_eq: $store}, is_active: {_eq: true}}}) {
      aggregate {
        sum {
          value
        }
      }
    }
    links: store_itemlinks {
      link
      id
      item_id
      absolute_link
    }
    store_warehouseitems {
      store_warehouselog {
        store_warehouseexpenses(order_by: {id: asc}, offset: 1) {
          amount
          id
        	warehouse_log_id
        }
        cost
        created_at
        is_active
        status
        id
        created_by_id
      }
      cost_brutto
      value
    }
    propositions_history:  store_itemstorepropositions(
      where: {
        store_amount: {_is_null: false}
      }
    ){
      id
      item_id
      created_at
      moderated_at
      store_amount
      status
      who_proposed_id
      who_moderated_id
      comment_who_proposed
      comment_who_moderated
    }
    store_itemstorepropositions(where:
      {
        status: {_eq: "new"}
        ${purchasePriceFilter ? ', purchase_price: {_is_null: false}' : ''}
        ${providedPriceFilter ? ', provided_price: {_is_null: false}' : ''}
        ${sellPriceFilter ? ', sell_price: {_is_null: false}' : ''}
        ${wholesalePriceFilter ? ', wholesale_price: {_is_null: false}' : ''}
        ${shopPriceFilter ? ', shop_price: {_is_null: false}' : ''}
        ${sellOtherCountriesPriceFilter ? ', sell_other_countries_price: {_is_null: false}' : ''}
        ${storeAmountFilter ? ', store_amount: {_is_null: false}' : ''}
        ${ndsFilter ? ', nds: {_is_null: false}' : ''}
      }) {
        id
        item_id
        moderated_at
        provided_price
        purchase_price
        sell_other_countries_price
        sell_price
        shop_price
        store_amount
        wholesale_price
        status
        nds
        comment_who_proposed
        comment_who_moderated
      }
  }
}`;

export const WAREHOUSE_PROPOSITIONS = (queryName) => gql`
${queryName} fetchStoreItemsPropositions(
    $stores: [Int!]!
  ) {
  store_category(where: {store_id: {_in: $stores}}) {
    store_id
    store_items {
      id
      store_itemstorepropositions(where: {status: {_eq: "new"}}) {
        id
      }
    }
  }
}`;

export const warehouseQuery = WAREHOUSE('query');

const ORDERS = (queryName) => gql`
${queryName} fetchOrders($store: Int!, $warehouselog_expense_type: Int) {
  orders: store_warehouselog(where: {store_id: {_eq: $store}, status: {_in: [100, 150]}, is_active: {_eq: true}}) {
    id
    trade_code
    cost
    nds
    created_at
    comment
    own_price
    card
    status
    contractor: customers_contractor {
      name
    }
    items: store_warehouseitems(where: {is_active: {_eq: true}}) {
      id
      cost_brutto_proposition
      item: store_item {
        id
        nds
        purchase_price
        store_itemstorepropositions(where: {status: {_eq: "new"}}) {
          purchase_price
          nds
        }
        name
        variant: store_attribute {
          name
        }
        is_expiration_date
      }
      cost_brutto
      nds_proposition
      value
      expirationdate: store_warehouseitemexpirationdates(where: {is_active: {_eq: true}}) {
        id
        value
        end_date
      }
    }
    expenses: store_warehouseexpenses_aggregate(where: {expenses_expensetype: {id: {_neq: $warehouselog_expense_type}}, is_active: {_eq: true}}) {
      aggregate {
        sum {
          amount
        }
      }
    }
    expenses_data: store_warehouseexpenses(where: { is_active: {_eq: true}}) {
      id
      payed
      amount
      type: expenses_expensetype {
        name
        id
      }
    }
    is_payed: store_warehouseexpenses_aggregate(where: {expenses_expensetype: {id: {_neq: $warehouselog_expense_type}}, payed: {_eq: false}, is_active: {_eq: true}}) {
      aggregate {
        sum {
          amount
        }
      }
    }
  }
}`;

const ORDER = (queryName) => gql`
${queryName} fetchOrder($orderId: Int!) {
  order: store_warehouselog_by_pk(id:  $orderId) {
    id
    trade_code
    contractor: contractor_id
    cost
    nds
    status
    store_id
    invoice
    own_price
    comment
    wait_date
    unofficial_purchase
    items: store_warehouseitems(where: {is_active: {_eq: true}}) {
      id
      item_id
      cost_brutto
      cost_brutto_proposition
      nds_proposition
      value
      unit_id
      item: store_item {
        id
        name
        store_itemstorepropositions(where: {status: {_eq: "new"}}) {
          purchase_price
          nds
          id
        }
        nds
        item_code
        purchase_price
        category_id
        category: store_category {
          id
          parent_id
          name
        }
        shop_price
        store_amount
        sent: store_orderitems_aggregate(where: {is_active: {_eq: true}, store_order: {status: {_eq: "500"}, payment_id: {_is_null: true}}}) {
          aggregate {
            sum {
              value
            }
          }
        }
        sell_price
        provided_price
        parent_id
        image
        description
        unofficial_item
        store_attribute {
          id
          name
          value
        }
        store_item_collection_attributes {
          store_collectionattribute {
            store_collectionattributes_attributes {
              store_attribute {
                name
                id
                value
              }
            }
            name
            id
          }
        }
        store_item_units_news {
          store_unit {
            id
            name
            value
            cost
          }
        }
      }
    }
  }
}`;

export const ordersQuery = ORDERS('query');
export const orderQuery = ORDER('query');

export const FETCH_RECEIVER = (queryName, department) => gql`
${queryName} fetchReceiver($account: Int!, $role: String,   ${
  department ? ' $department: String!' : ''
} ) {
  customers_receiver(where: {
    _or: [{account_id: {_eq: $account}},
    {role: {_eq: $role}},
    ${department ? '{core_department: {name: {_eq: $department}}}' : ''}
    ]
  }
    ) {
      price
      id
      account_id
      department: department_id
      role
  }
}
`;

export const CATEGORY = gql`
  query fetchCategory {
    category: store_category {
      value: id
      name
    }
  }
`;

export const FORCE_MODERATION = gql`
  query fetchCategory {
    moderations: store_moderationsconfigs_by_pk(id: 1) {
      order_max_percent
      order_min_percent
      sell_max_percent
      sell_min_percent
      warehouselog_max_percent
      warehouselog_min_percent
    }
  }
`;

export const FETCH_WAREHOUSE_ITEMS = (
  isModerator,
  categoriesFilter,
  offerFilter,
  hideHiddenItemsFilter,
  isProduced,
  isCollection,
  sorted,
) => gql`
query fetchWarehouseItems($store: Int!, $categories: [Int!]) {
    items: store_item(
      where: {
        ${
          isModerator
            ? hideHiddenItemsFilter
              ? ''
              : ', available: {_eq: true}'
            : ', available: {_eq: true}'
        }
        can_be_produced: {_eq: ${isProduced ? 'true' : 'false'}}
        is_collection: {_eq: ${isCollection ? 'true' : 'false'}}
        store_category: {
          store_id: {_eq: $store}
          ${categoriesFilter ? 'id: {_in: $categories }' : ''}
        }
        ${
          offerFilter
            ? `, store_itemstorepropositions: {
            status: {_eq: "new"}
            ${offerFilter ? `, ${offerFilter.value}:  {_is_null: false}}` : ''}
        `
            : ''
        }
      }
      ${sorted ? `order_by: { ${sorted} }` : 'order_by: { store_amount: desc }'}
      )
      {
        unofficial_item
        id
        item_code
        provided_price_percentage
        sell_other_countries_price_percentage
        sell_price_percentage
        shop_price_percentage
        wholesale_price_percentage
        parent: parent_id
        name
        variant: store_attribute {
          name
        }
        marketable: store_orderitems(limit: 10, where: {is_active: {_eq: true}}) {
          id
          details: store_order {
            created_at
          }
        }
      }
  }`;

export const FETCH_WAREHOUSE_ITEMS_DATA = (sorted) => gql`
  query fetchWarehouseItemsData($ids: [Int!], $warehouselog_expense_type: Int) {
    items: store_item(where: { id: { _in: $ids } }
      ${sorted ? `order_by: { ${sorted} }` : 'order_by: { store_amount: desc }'}
      ) {
      id
      parent_id
      wix_item
      item_code
      provided_price
      purchase_price
      sell_other_countries_price
      wholesale_price
      purchase_delivery_price
      sell_price
      store_amount
      average_remains
      shop_price
      available
      description
      image
      nds
      unofficial_item
      pay_full_points
      pay_half_points
      sell_other_countries_price_hide
      sell_price_hide
      shop_price_hide
      purchase_price_hide
      provided_price_hide
      wholesale_price_hide
      is_collection
      can_be_produced
      no_nds_purchase
      provided_price_percentage
      sell_other_countries_price_percentage
      sell_price_percentage
      shop_price_percentage
      wholesale_price_percentage
      average_internet_price
      is_expiration_date
      expirationdates: store_itemexpirationdates(where: {is_active: {_eq: true}}, order_by: {end_date: asc}) {
        is_active
        item_id
        end_date
        id
        transactions: store_itemexpirationdatetransactions(limit: 1, order_by: {id: desc}) {
          quantity
        }
      }
      store_item_collection_attributes {
        id
        collectionattributes_id
      }
      attribute: store_attribute {
        name
        id
      }
      item_units: store_item_units_news {
        unit: store_unit {
          id
          name
          value
          cost
        }
      }
      name
      units
      category: store_category {
        id
        name
        parent_id
      }
      orders: store_orderitems(
        where: { is_active: {_eq: true}, store_order: { status: { _nin: ["2000", "1000", "700"] } } }
        order_by: { id: desc }
      ) {
        id
        value
        details: store_order {
          payment_id
          status
          parlor: parlor_id
          created_at
        }
      }
      store_warehouseitems(limit: 1, order_by: { id: desc }) {
        store_warehouselog {
          expenses: store_warehouseexpenses_aggregate(where: {expenses_expensetype: {id: {_neq: $warehouselog_expense_type}}}) {
            aggregate {
              sum {
                amount
              }
            }
          }
          items: store_warehouseitems {
            cost_brutto_proposition
            cost_brutto
            value
          }
        }
        cost_brutto_proposition
        cost_brutto
        value
      }
      expected: store_warehouseitems_aggregate(
        where: { store_warehouselog: { status: { _eq: 100 }, is_active: {_eq: true} } }
      ) {
        aggregate {
          sum {
            value
          }
        }
      }
      store_itemstorepropositions(where: { status: { _eq: "new" } }) {
        id
        item_id
        moderated_at
        provided_price
        purchase_price
        sell_other_countries_price
        sell_price
        shop_price
        store_amount
        wholesale_price
        status
        nds
        comment_who_proposed
        comment_who_moderated
      }
    }
  }
`;

export const FETCH_CATEGORIES_STORE = () => gql`
  query fetchCategoriesStore($store: Int!) {
    store_category(where: { store_id: { _eq: $store }, available: { _eq: true } }) {
      value: id
      label: name
      parent: parent_id
    }
  }
`;

export const FETCH_CREDITS_CARDS = gql`
  query fetchCreditCards($store: Int!) {
    cards: store_storecard(where: { store_id: { _eq: $store } }) {
      id
      credit_card
      card_description
    }
  }
`;

export const FETCH_CREDITS_CARDS_WAREHOUSES = gql`
  query fetchCreditCards($stores: [Int]!) {
    cards: store_storecard(where: { store_id: { _in: $stores } }) {
      id
      credit_card
      card_description
    }
  }
`;

export const FETCH_ITEM_STORE = (lang) => gql`
  query fetchItemStore($id: Int!) {
    item: store_item_by_pk(id: $id) {
      id
      name
      name_ru
      name_pl
      name_de
      name_en
      name_uk
      wix_item
      photo_collections: store_itemphotocollections {
        id
        photos: store_itemphotocollection_photos {
          store_itemphoto {
            photo
            id
          }
          id
        }
        collection_name
      }
      descriptions: store_itemdescriptions {
        id
        name
        full_description_ru
        full_description_pl
        full_description_en
        full_description_uk
        full_description_de
        full_description_lt
        full_description_et
      }
      parent: store_item {
        name
        id
        item_code
        store_amount
      }
      
      variants: store_items {
        id
        name
        item_code
        attribute: store_attribute {
          name
          value
        }
        shop_price
      }

      tags: store_item_tags {
        tag: store_tagitem {
          id
          name_${lang.replace('G', '')}
        }
      }
      item_code
      image
      description
      units
      statuses
      wait_period
      recommendations
      quality_certificate_qr
      shop_price
      provided_price
      sell_price
      photos: store_photocollectionitems {
        photo: store_photoitem {
          photo
          id
        }
        id
        collection
        photo_id
      }
      average_internet_price
      item_units: store_item_units_news {
        unit_id
        item_id
        store_unit {
          title
          value
          name
        }
      }
      is_expiration_date
      expirationdates: store_itemexpirationdates(where: {is_active: {_eq: true}}, order_by: {end_date: asc}) {
        is_active
        item_id
        end_date
        id
        transactions: store_itemexpirationdatetransactions(limit: 1, order_by: {id: desc}) {
          quantity
        }
      }
      variant: store_attribute {
        name
        id
      }
      category: store_category {
        lft
        level
        store_id
        store_store {
          store_onlineshops {
            id
            store_id
            online_shop
            domain_online_shop
            domain_shop
            client_secret
            access_token
          }
        }
        id
        parent_id
        name
        tree_id
      }
      store_item_collection_attributes {
        store_collectionattribute {
          store_collectionattributes_attributes {
            store_attribute {
              name
              id
              value
            }
          }
          name
          id
          is_wix
        }
      }
      links: store_itemlinks {
        link
        id
        item_id
        absolute_link
      }
      can_be_produced
      is_collection
      recipe_produced: storeComponentitemsByProducedItemId {
        amount
        id
        item_id
        item: store_item {
          name
          variant: store_attribute {
            name
          }
        }
      }
      recipe_collection: store_collectionitems {
        amount
        id
        item_id
        item: storeItemByItemId {
          name
          variant: store_attribute {
            name
          }
        }
      }
    }
  }
`;

export const FETCH_ITEM_STORE_HISTORY = gql`
  query fetchItemStoreHistory($id: Int!, $offset: Int!, $limit: Int!) {
    total: store_warehouseitem_aggregate(where: { item_id: { _eq: $id } }) {
      aggregate {
        count
      }
    }
    data: store_warehouseitem(
      offset: $offset
      limit: $limit
      where: { item_id: { _eq: $id }, is_active: { _eq: true } }
      order_by: { id: desc }
    ) {
      id
      item_id
      value
      cost_brutto_proposition
      cost_brutto
      store_warehouselog {
        created_at
        complited_at
        status
        store: store_store {
          name
        }
        card
        id
        invoice
        contractor_id
        comment
      }
    }
  }
`;

export const FETCH_ORDERS_ITEM_STORE_HISTORY = (dateFilterType) => gql`
  query fetchOrdersItemStoreHistory($id: Int!, $offset: Int!, $limit: Int!, $from: timestamptz!, $to: timestamptz!) {
    total: store_orderitem_aggregate(where: { item_id: { _eq: $id },is_active: { _eq: true},
      store_order : {
        ${dateFilterType === 'sending_date' ? 'sending_date: {_gte: $from, _lte: $to}' : ''}
        ${dateFilterType === 'created_at' ? 'created_at: {_gte: $from, _lte: $to}' : ''}
      } }) {
      aggregate {
        count
      }
    }
    total_items_ordered: store_orderitem_aggregate(
      where: { item_id: { _eq: $id } 
      is_active: { _eq: true},
      store_order : {
        ${dateFilterType === 'sending_date' ? 'sending_date: {_gte: $from, _lte: $to}' : ''}
        ${dateFilterType === 'created_at' ? 'created_at: {_gte: $from, _lte: $to}' : ''}
      }
    }
    ) {
      aggregate {
        sum {
          value
        }
      }
    }
    total_items_received: store_orderitem_aggregate(
      where: { item_id: { _eq: $id } 
      is_active: { _eq: true},
      store_order : {
        ${dateFilterType === 'sending_date' ? 'sending_date: {_gte: $from, _lte: $to}' : ''}
        ${dateFilterType === 'created_at' ? 'created_at: {_gte: $from, _lte: $to}' : ''}
      }
    }
    ) {
      aggregate {
        sum {
          received
        }
      }
    }
    data: store_orderitem(
      offset: $offset
      limit: $limit
      where: {
        item_id: { _eq: $id },
        is_active: { _eq: true},
        store_order : {
          ${dateFilterType === 'sending_date' ? 'sending_date: {_gte: $from, _lte: $to}' : ''}
          ${dateFilterType === 'created_at' ? 'created_at: {_gte: $from, _lte: $to}' : ''}
        }
      }
      order_by: { id: desc }
    ) {
      id
      item_id
      received
      value
      store_order {
        price_type
        status
        created_at
        sending_date
        parlor_id
        parlor: core_parlor {
          name
        }
        expenses_incomingpayment {
          created_at
        }
        receiver_id
        receiver: customers_receiver {
          name
        }
        trade_code
        id
        created_at
      }
      wholesale_price
      shop_price
      sell_price
      sell_other_countries_price
      purchase_price
      provided_price
    }
  }
`;

export const FETCH_PROPOSITIONS_ITEM_STORE_HISTORY = gql`
  query fetchPropositionsItemStoreHistory($id: Int!, $offset: Int!, $limit: Int!) {
    total: store_itemstoreproposition_aggregate(where: { item_id: { _eq: $id } }) {
      aggregate {
        count
      }
    }
    data: store_itemstoreproposition(
      offset: $offset
      limit: $limit
      where: { item_id: { _eq: $id } }
      order_by: { id: desc }
    ) {
      provided_price
      purchase_price
      status
      shop_price
      sell_price
      sell_other_countries_price
      nds
      wholesale_price
      who_moderated_id
      who_proposed_id
      store_amount
      item_id
      id
      comment_who_moderated
      comment_who_proposed
      created_at
      moderated_at
      proposed_user: accountsUserByWhoProposedId {
        first_name
      }
      moderated_user: accounts_user {
        id
        first_name
      }
      accounts_user {
        id
        first_name
      }
    }
  }
`;

export const PARLORS_PAYMENTS = () => gql`
  query fetchPalorPayments($store: Int!) {
    payments: expenses_incomingpayment(
      where: { store_order: { store_id: { _eq: $store } }, parlor_id: { _is_null: false } }
      order_by: { id: asc }
    ) {
      wallet_id
      payed_by_id
      payed_at
      payed
      store_order {
        store_id
      }
      value
      parlor: core_parlor {
        value: id
        label: name
      }
    }
  }
`;

export const FETCH_SHOPER_ORDERS = () => {
  return gql`
    query fetchShoperOrders(
      $status: [smallint!]
      $offset: Int!
      $limit: Int!
      $store: Int
      $from: timestamptz
      $to: timestamptz
    ) {
      orders_count: store_ordershoper_aggregate(
        where: {
          status: { _in: $status }
          store_id: { _eq: $store }
          created_at: { _gte: $from, _lte: $to }
        }
      ) {
        aggregate {
          count
        }
      }
      orders: store_ordershoper(
        limit: $limit
        offset: $offset
        where: {
          status: { _in: $status }
          store_id: { _eq: $store }
          created_at: { _gte: $from, _lte: $to }
        }
        order_by: { created_at: desc }
      ) {
        id
        moderated_by_id
        store_order {
          id
        }
        store: store_store {
          value: id
          label: name
        }
        status
        created_at
        created_by_email
        external_order_shoper_id
      }
    }
  `;
};

export const FETCH_SHOPER_ORDER = () => {
  return gql`
    query fetchShoperOrder($id: Int!) {
      order: store_ordershoper_by_pk(id: $id) {
        id
        moderated_by_id
        store_order {
          id
        }
        status
        created_at
        created_by_email
        created_by_first_name
        created_by_last_name
        external_order_shoper_id
        external_customer_id
        delivery_phone
        shipping_price
        store: store_store {
          value: id
          label: name
        }
        items: store_orderitemshopers {
          external_item_shoper_id
          id
          item_code
          moderated_by_id
          order_shoper_id
          price
          quantity
          status
          name
          name_option
          discount_perc
          attributes: store_orderitemattributesshopers {
            attribute: store_attribute {
              id
              value
              name
            }
            collectionattribute: store_collectionattribute {
              name
              id
            }
          }
        }
      }
    }
  `;
};

export const FETCH_ITEMS_DATA = () => {
  return gql`
    query fetchItemsData($item_codes: [String!]) {
      items: store_item(where: { item_code: { _in: $item_codes } }) {
        id
        name
        item_code
        store_attribute {
          name
        }
        purchase_price
        provided_price
        sell_price
        shop_price
        wholesale_price
        sell_other_countries_price
        nds
        category: store_category {
          store: store_store {
            id
            name
          }
        }
        store_amount
        image
        category: store_category {
          name
          id
        }
      }
    }
  `;
};

export const FETCH_WAREHOUSE_INFO = gql`
  query fetchWarehouseInfo($store: Int!) {
    items: store_item(
      where: {
        available: { _eq: true }
        can_be_produced: { _eq: false }
        is_collection: { _eq: false }
        store_category: { store_id: { _eq: $store } }
      }
    ) {
      id
      parent: parent_id
      name
      variant: store_attribute {
        name
      }

      store_amount
      purchase_price
      purchase_delivery_price
      orders: store_orderitems(
        where: {
          is_active: { _eq: true }
          store_order: { status: { _nin: ["2000", "1500", "1000", "700"] } }
        }
        order_by: { id: desc }
      ) {
        id
        value
        details: store_order {
          status
          parlor: parlor_id
          created_at
        }
      }
    }
  }
`;

export const FETCH_ORDERS_CUSTOMERS = (
  sorted,
  dateFilterType,
  receiverFilter,
  senderFilter,
  cardFilter,
  selectParlor,
  customer,
) => gql`
query fetchOrdersCustomer( 
  $offset: Int!,
  $user: Int!,
   ${cardFilter ? `$card: Int,` : ``}
   ${receiverFilter ? `$receiver: Int,` : ``}
   ${senderFilter ? `$sender: Int,` : ``}
   $from: timestamptz, $to: timestamptz
   ) {
  orders_count:  store_order_aggregate(where: {
    ${dateFilterType === 'sending_date' ? 'sending_date: {_gte: $from, _lte: $to}' : ''}
    ${dateFilterType === 'created_at' ? 'created_at: {_gte: $from, _lte: $to}' : ''}
    ${
      dateFilterType === 'payed_at'
        ? 'expenses_incomingpayment: {payed_at:{ _gte: $from, _lte: $to}}'
        : ''
    }
    created_by_id: {_eq: $user}
    customer_id: {_is_null: false},
    ${cardFilter ? `store_storecard: {id : {_eq: $card}}` : ``}
    ${receiverFilter ? `receiver_id:  {_eq: $receiver}` : ``}
    ${senderFilter ? `sender_id: {_eq: $sender}` : ``}
    } ) {
    aggregate {
      count
    }
  },
  total_price:   store_order_aggregate(where: {
    ${dateFilterType === 'sending_date' ? 'sending_date: {_gte: $from, _lte: $to}' : ''}
    ${dateFilterType === 'created_at' ? 'created_at: {_gte: $from, _lte: $to}' : ''}
    ${
      dateFilterType === 'payed_at'
        ? 'expenses_incomingpayment: {payed_at:{ _gte: $from, _lte: $to}}'
        : ''
    }
    created_by_id: {_eq: $user}
    customer_id: {_is_null: false},
    ${cardFilter ? `store_storecard: {id : {_eq: $card}}` : ``}
    ${receiverFilter ? `receiver_id:  {_eq: $receiver}` : ``}
    ${senderFilter ? `sender_id: {_eq: $sender}` : ``}
    } ) {
    aggregate {
      sum {
        price
      }
    }
  }
  orders: store_order(limit: 10, offset: $offset, where: {
    ${dateFilterType === 'sending_date' ? 'sending_date: {_gte: $from, _lte: $to}' : ''}
    ${dateFilterType === 'created_at' ? 'created_at: {_gte: $from, _lte: $to}' : ''}
    ${
      dateFilterType === 'payed_at'
        ? 'expenses_incomingpayment: {payed_at:{ _gte: $from, _lte: $to}}'
        : ''
    }
    customer_id: {_is_null: false},
    created_by_id: {_eq: $user},
    ${cardFilter ? `store_storecard: {id : {_eq: $card}}` : ``}
    ${receiverFilter ? `receiver_id:  {_eq: $receiver}` : ``}
    ${senderFilter ? `sender_id: {_eq: $sender}` : ``}
  }, ${
    sorted
      ? `order_by: {${sorted[0]?.id}: ${sorted[0]?.desc ? 'desc' : 'asc'}}`
      : 'order_by: { created_at: desc }'
  } ) {
    id
    created_at
    sending_date
    status
    note
    trade_code
    payment_id
    receiver_id
    sender_id
    nds
    customer_id
    price
    customer: customers_customer {
      id
      full_name
    }
    receiver: customers_receiver {
      name
      id
    }
    store: store_store {
      id
      name
      store_store_owners {
        accounts_user {
          id
          first_name
          middle_name
          last_name
        }
      }
    }
    not_turned: store_orderitems_aggregate(where: {turned: {_is_null: true}, is_active: {_eq: true}}) {
      aggregate {
        count
      }
    }
    parlor: core_parlor {
      id
      name
    }
    payment: expenses_incomingpayment {
      id
      payed_at
      store_storecard {
        label: credit_card
        value: id
      }
      value
    }
    order_item: store_orderitems(where: {is_active: {_eq: true}}) {
      item: store_item {
        provided_price
        nds
        sell_other_countries_price
        provided_price
      }
      provided_price
      shop_price
      sell_other_countries_price
      turned
      received
      store_unit {
        id
        name
        value
        cost
      }
      value
      store_orderitemattributes {
        store_attribute {
          id
          name
          value
        }
        store_collectionattribute {
          name
          id
        }
      }
    }
  }
}
`;

export const FETCH_SELLERS = () => gql`
  query fetchSellers($from: timestamptz, $to: timestamptz) {
    sellers_total: customers_seller_aggregate {
      aggregate {
        count
      }
    }
    sellers: customers_seller {
      id
      is_active
      is_motivation
      user: accounts_user {
        first_name
        id
        last_name
      }
      user_orders: accounts_user {
        orders: store_orders_aggregate(
          where: {
            expenses_incomingpayment: { payed_at: { _gte: $from, _lte: $to } }
            customer_id: { _is_null: false }
            status: { _eq: "700" }
          }
        ) {
          aggregate {
            sum {
              price
            }
          }
        }
      }
    }
  }
`;

export const FETCH_SELLERS_MOTIVATIONS = () => gql`
  query fetchSellers($users: [Int!]) {
    motivation: motivation_motivation(where: { user_id: { _in: $users } }) {
      motivation_order: motivation_ordermotivations {
        id
        motivation_id
        percent
      }
      id
      user_id
    }
  }
`;

export const FETCH_SELLERS_BONUS = () => gql`
  query fetchSellerBonus($users: [Int!]) {
    salary: salary_salary(where: { user_id: { _in: $users } }) {
      orders
      user_id
    }
  }
`;

export const FETCH_SELLER_ORDERS = (sorted) => gql`
  query fetchSellers($user: Int!, $limit: Int!, $offset: Int!) {
    total: store_order_aggregate(
      where: {
        status: { _eq: "700" }
        created_by_id: { _eq: $user }
        customer_id: { _is_null: false }
      }
      ${sorted ? `order_by: { ${sorted} }` : 'order_by: { created_at: desc }'}
    ) {
      aggregate {
        count
      }
    }
    orders: store_order(
      where: {
        status: { _eq: "700" }
        created_by_id: { _eq: $user }
        customer_id: { _is_null: false }
      }
      limit: $limit
      offset: $offset
    ) {
      created_by_id
      created_at
      price
      id
      status
      updated_at
    }
  }
`;

export const FETCH_PAYMENTS = () => gql`
  query fetchClientPayments($client: Int!) {
    expenses_incomingpayment(
      where: { store_order: { receiver_id: { _eq: $client } }, payed: { _eq: false } }
    ) {
      id
      note
      created_at
    }
  }
`;

export const FETCH_WAREHOUSELOGS_MODERATION = () => gql`
  query fetchWarehouselogsModeration {
    store_warehouselogmoderation(where: { status: { _eq: 100 } }) {
      id
      created_at
      status
      contractor_store
      from_order_crm
    }
  }
`;

export const FETCH_WAREHOUSELOG_MODERATION = () => gql`
  query fetchWarehouselogsModeration($id: Int!) {
    warehouselog: store_warehouselogmoderation_by_pk(id: $id) {
      id
      created_at
      contractor_store
      status
      store: store_store {
        label: name
        value: id
      }
      from_order_crm
      items: store_warehouselogitemmoderations {
        id
        item_code
        log_id
        quantity
        price
        status
        name
        expiration_date
      }
    }
  }
`;

export const FETCH_STORES_VALUES = () => gql`
  query fetchWarehouseValuesLogs($store: Int!) {
    logs: store_storelog(
      where: { store_id: { _eq: $store }, name: { _in: [total_items, total_purchase_price] } }
    ) {
      id
      value
      store_id
      name
      created_at
    }
  }
`;

export const FETCH_ORDERS_FOR_CURRENT_MONTH = () => gql`
  query fetchCountOrdersForMonth($parlor: Int!, $from: timestamptz) {
    orders: store_ordersource_aggregate(
      where: {
        store_orders: { parlor_id: { _eq: $parlor } }
        created_at: { _gte: $from }
        fine_delivery: { _eq: true }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const FETCH_UNITS = () => gql`
  query fetchUnits($limit: Int, $offset: Int) {
    total: store_unit_aggregate {
      aggregate {
        count
      }
    }
    units: store_unit(limit: $limit, offset: $offset, order_by: { id: desc }) {
      cost
      name
      id
      value
      title
    }
  }
`;

export const FETCH_ATTRIBUTES = () => gql`
  query fetchAttributes {
    attributes: store_attribute(order_by: { id: desc }) {
      label: name
      value: id
    }
  }
`;

export const FETCH_CATEGORIES_DATA = () => gql`
  query fetchCategories($store: Int) {
    categories: store_category(
      where: { store_id: { _eq: $store }, available: { _eq: true }, parent_id: { _is_null: true } }
    ) {
      label: name
      value: id
      childs: store_categories {
        label: name
        value: id
      }
    }
  }
`;

export const FETCH_SUBCATEGORIES_DATA = () => gql`
  query fetchSubCategories($store: Int, $parent: Int) {
    categories: store_category(
      where: { store_id: { _eq: $store }, available: { _eq: true }, parent_id: { _eq: $parent } }
    ) {
      label: name
      value: id
    }
  }
`;

export const FETCH_ANALYTICS_CUSTOMERS_DATA = () => gql`
  query fetchAnalyticsCustomers($limit: Int, $offset: Int) {
    customers: customers_customer(where: { potential_customer: { _eq: true } }) {
      id
      full_name
      orders: store_orders(where: { status: { _eq: "700" } }) {
        id
        price
      }
    }
  }
`;

export const FETCH_ANALYTICS_CUSTOMER_DATA = () => gql`
  query fetchAnalyticsCustomer($customer: Int!) {
    customer: customers_customer_by_pk(id: $customer) {
      id
      full_name
      email
      phone_number
      orders: store_orders(where: { status: { _eq: "700" } }) {
        id
        price
        created_at
      }
    }
  }
`;

export const FETCH_ONLINE_SHOP_SHOPER = () => gql`
  query fetchShoper($store: Int!) {
    online_shop: store_onlineshop(
      where: { store_id: { _eq: $store }, online_shop: { _eq: shoper } }
    ) {
      id
      domain_online_shop
      client_secret
      domain_shop
      online_shop
      access_token
    }
  }
`;

export const FETCH_ITEM_TAGS = () => gql`
  query fetchItemTags {
    tags: store_tagitem(order_by: { id: desc }) {
      id
      name_en
      name_ru
      name_de
      name_uk
      name_pl
      category: store_tagitemcategory {
        label: name
        value: id
      }
    }

    categories: store_tagitemcategory {
      label: name
      value: id
    }
  }
`;

export const FETCH_ADDITIONAL_OPERATIONS = () => gql`
  query fetchAdditionalOperations {
    additional_operations: store_additionaloperation(order_by: { id: desc }) {
      id
      is_active
      name
      type
      value
    }
  }
`;

export const FETCH_ANALYTICS_SENDERS_DATA = () => gql`
  query fetchAnalyticsCustomers($from: timestamptz, $to: timestamptz) {
    senders: accounts_user(
      where: { storeOrdersBySendingById_aggregate: { count: { predicate: { _gte: 1 } } } }
    ) {
      first_name
      last_name
      orders: storeOrdersBySendingById(
        where: { status: { _neq: 1000 }, sending_date: { _gte: $from, _lte: $to } }
      ) {
        id
        customer_id
        parlor_id
        receiver_id
        sending_date
      }
    }
  }
`;

export const FETCH_RECEIVER_DATA = () => gql`
  query fetchReceiverData($id: Int!) {
    receiver: customers_receiver_by_pk(id: $id) {
      name
      id
      type
      cards: store_receivercards {
        credit_card
        card_description
        id
        expenses: expenses_incomingpayments(order_by: { created_at: desc }) {
          id
          note
          created_at
        }
        store_receivertransactions(order_by: { created_at: desc }) {
          entity_id
          amount
          balance
          reference
          created_at
          accounts_user {
            first_name
            last_name
          }
        }
      }
    }
  }
`;

export const FETCH_WAREHOUSE_LANDINGS = () => gql`
  query fetchWarehouseLandings($store: Int!) {
    landings: shoper_warehouselanding(where: { store_id: { _eq: $store } }) {
      id
      name
      url
      store_id
      is_active
    }
  }
`;

export const FETCH_WAREHOUSE_LANDING_DATA = () => gql`
  query fetchWarehouseLandingData($id: bigint!) {
    landing: shoper_warehouselanding_by_pk(id: $id) {
      id
      title
      name
      url
      store_id
      seller: accounts_user {
        first_name
        last_name
      }
      images: shoper_imagelandings(where: { is_active: { _eq: true } }) {
        id
        image
        is_active
      }
      logo: shoper_logolandings(order_by: { id: asc }) {
        id
        image_landing_id
        warehouse_landing_id
        image: shoper_imagelanding {
          image
          id
        }
      }
      footer: shoper_footerlandings {
        id
        is_active
        is_hidden
        key
        meta
      }
    }
  }
`;
export const FETCH_TRANSACTIONS_PAYEMENTS = () => gql`
  query fetchReceiverData($ids: [Int!]) {
    transactions: store_storetransaction(
      where: {
        entity_id: { _in: $ids }
        django_content_type: { model: { _eq: "warehouseexpense" } }
      }
    ) {
      entity_id
      id
      amount
    }
  }
`;

export const fetchWholesaleOrdersQuery = (
  cardFilter,
  senderFilter,
  receiverFilter,
  sorted,
  dateFilterType,
) => gql`
query fetchOrders($stores: [Int!], $parlor: Boolean, $unofficial_order: Boolean, $status: [smallint!], $offset: Int!,
   ${cardFilter ? `$card: Int,` : ``}
   ${receiverFilter ? `$receiver: Int,` : `$receiver: Boolean`}
   $from: timestamptz, $to: timestamptz
   ) {
  orders_count:  store_order_aggregate(where: { 
    ${dateFilterType === 'sending_date' ? 'sending_date: {_gte: $from, _lte: $to}' : ''}
    ${dateFilterType === 'created_at' ? 'created_at: {_gte: $from, _lte: $to}' : ''}
    ${
      dateFilterType === 'payed_at'
        ? 'expenses_incomingpayment: {payed_at: {_gte: $from, _lte: $to}}'
        : ''
    }
    unofficial_order: {_eq: $unofficial_order}
    store_id: {_in: $stores}
    status: {_in: $status},
    price_type: {_eq: "wholesale_price"}
    ${cardFilter ? `store_storecard: {id : {_eq: $card}}` : ``}
    ${receiverFilter ? `receiver_id:  {_eq: $receiver}` : `receiver_id: {_is_null: $receiver }`}
    } ) {
    aggregate {
      count
    }
  },
  total_orders_id:  store_order(where: { 
    ${dateFilterType === 'sending_date' ? 'sending_date: {_gte: $from, _lte: $to}' : ''}
    ${dateFilterType === 'created_at' ? 'created_at: {_gte: $from, _lte: $to}' : ''}
    ${
      dateFilterType === 'payed_at'
        ? 'expenses_incomingpayment: {payed_at: {_gte: $from, _lte: $to}}'
        : ''
    }
    unofficial_order: {_eq: $unofficial_order}
    store_id: {_in: $stores}
    status: {_in: $status},
    price_type: {_eq: "wholesale_price"}
    ${cardFilter ? `store_storecard: {id : {_eq: $card}}` : ``}
    ${receiverFilter ? `receiver_id:  {_eq: $receiver}` : `receiver_id: {_is_null: $receiver }`}
    } ) {
    id
  },
  orders: store_order(limit: 10, offset: $offset, where: {
    ${dateFilterType === 'sending_date' ? 'sending_date: {_gte: $from, _lte: $to}' : ''}
    ${dateFilterType === 'created_at' ? 'created_at: {_gte: $from, _lte: $to}' : ''}
    ${
      dateFilterType === 'payed_at'
        ? 'expenses_incomingpayment: {payed_at: {_gte: $from, _lte: $to}}'
        : ''
    }
    unofficial_order: {_eq: $unofficial_order}
    store_id: {_in: $stores}
    status: {_in: $status},
    ${cardFilter ? `store_storecard: {id : {_eq: $card}}` : ``}
    ${receiverFilter ? `receiver_id:  {_eq: $receiver}` : `receiver_id: {_is_null: $receiver }`}
    price_type: {_eq: "wholesale_price"}
  }, ${
    sorted
      ? `order_by: {${sorted[0]?.id}: ${sorted[0]?.desc ? 'desc' : 'asc'}}`
      : 'order_by: { created_at: desc }'
  } ) {
    id
    unofficial_order
    created_at
    sending_date
    status
    note
    trade_code
    payment_id
    receiver_id
    sender_id
    nds
    customer_id
    price
    positive: store_orderadditionaloperations_aggregate(where: {type: {_eq: "positive"}, is_active: {_eq:true}}) {
      aggregate {
        sum {
          value
        }
      }
    }
    negative: store_orderadditionaloperations_aggregate(where: {type: {_eq: "negative"}, is_active: {_eq:true}}) {
      aggregate {
        sum {
          value
        }
      }
    }
    customer: customers_customer {
      id
      full_name
    }
    receiver: customers_receiver {
      name
      id
    }
    store: store_store {
      id
      name
      store_store_owners {
        accounts_user {
          id
          first_name
          middle_name
          last_name
        }
      }
    }
    not_turned: store_orderitems_aggregate(where: {turned: {_is_null: true}, is_active: {_eq: true}}) {
      aggregate {
        count
      }
    }
    parlor: core_parlor {
      id
      name
    }
    payment: expenses_incomingpayment {
      id
      payed_at
      store_storecard {
        label: credit_card
        value: id
      }
      value
    }
    order_item: store_orderitems(where: {is_active: {_eq: true}}) {
      item: store_item {
        provided_price
        nds
        sell_other_countries_price
        provided_price
      }
      provided_price
      shop_price
      sell_other_countries_price
      turned
      received
      store_unit {
        id
        name
        value
        cost
      }
      value
      store_orderitemattributes {
        store_attribute {
          id
          name
          value
        }
        store_collectionattribute {
          name
          id
        }
      }
    }
  }
}
`;

export const fetchWholesaleTotalOrders = (
  cardFilter,
  senderFilter,
  receiverFilter,
  sorted,
  dateFilterType,
) => gql`
query fetchOrdersTotal($stores: [Int!], $parlor: Boolean, $unofficial_order: Boolean, $status: [smallint!],
  ${cardFilter ? `$card: Int,` : ``}
  ${receiverFilter ? `$receiver: Int,` : `$receiver: Boolean`}
  $from: timestamptz, $to: timestamptz
  ){
  total_price:  store_order(where: { 
    ${dateFilterType === 'sending_date' ? 'sending_date: {_gte: $from, _lte: $to}' : ''}
    ${dateFilterType === 'created_at' ? 'created_at: {_gte: $from, _lte: $to}' : ''}
    ${
      dateFilterType === 'payed_at'
        ? 'expenses_incomingpayment: {payed_at: {_gte: $from, _lte: $to}}'
        : ''
    }
    unofficial_order: {_eq: $unofficial_order}
    parlor_id: {_is_null: true},
    store_id: {_in: $stores}
    status: {_in: $status},
    price_type: {_eq: wholesale_price},
    ${cardFilter ? `store_storecard: {id : {_eq: $card}}` : ``}
    ${receiverFilter ? `receiver_id:  {_eq: $receiver}` : `receiver_id: {_is_null: $receiver }`}
    }) {
      price
      unofficial_order
      price_type
      receiver: customers_receiver {
        is_vat
      }
      order_items: store_orderitems(where: {is_active: {_eq: true}}) {
        wholesale_price
        received
        value
        turned
        nds
        purchase_price
        purchase_delivery_price
        item: store_item {
          nds
        }
        store_orderitemattributes {
          store_attribute {
            id
            name
            value
          }
        }
      }
      positive: store_orderadditionaloperations_aggregate(where: {type: {_eq: "positive"}, is_active: {_eq:true}}) {
        aggregate {
          sum {
            value
          }
        }
      }
      negative: store_orderadditionaloperations_aggregate(where: {type: {_eq: "negative"}, is_active: {_eq:true}}) {
        aggregate {
          sum {
            value
          }
        }
      }
    }
}
`;

export const FETCH_BESTSELLERS = () => gql`
  query fetchBestSellerData {
    best_sellers: shoper_bestseller(where: { is_active: { _eq: true } }) {
      id
      item: store_item {
        name
        attribute: store_attribute {
          name
        }
      }
    }
  }
`;
